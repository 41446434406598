import {
  InvestorEntity,
  InvestorEntityStatus,
  InvestorEntityType,
} from '@yieldstreet/platform-kit';

export type BaseActionParams = Record<string, any>;

export type BaseAppActionParamsMap<Map extends Record<AppAction, Record<string, any>>> = Map;

export enum AppAction {
  // KYC
  START_KYC = 'START_KYC',
  CONTINUE_KYC = 'CONTINUE_KYC',

  // Beneficial Owners KYC
  SUBMIT_BENEFICIAL_OWNERS_KYC_INFO = 'SUBMIT_BENEFICIAL_OWNERS_KYC_INFO',
  UPLOAD_BENEFICIAL_OWNERS_KYC_DOCS = 'UPLOAD_BENEFICIAL_OWNERS_KYC_DOCS',
  INVITE_BENEFICIAL_OWNERS = 'INVITE_BENEFICIAL_OWNERS',

  // Bank Linking
  LINK_BANK_ACCOUNT = 'LINK_BANK_ACCOUNT',
  VERIFY_MICRO_DEPOSITS = 'VERIFY_MICRO_DEPOSITS',

  // Accreditation
  SUBMIT_ACCREDITATION = 'SUBMIT_ACCREDITATION',
  EDIT_ACCREDITATION = 'EDIT_ACCREDITATION',
  RENEW_ACCREDITATION = 'RENEW_ACCREDITATION',
  UPDATE_ACCREDITATION_DOCUMENTATION = 'UPDATE_ACCREDITATION_DOCUMENTATION',

  // Transactions
  TRANSACTIONS = 'TRANSACTIONS',

  // Managed Portfolios
  CREATE_INVESTOR_ENTITY = 'CREATE_INVESTOR_ENTITY',
  SUBMIT_EXTRA_KYC_INFO = 'SUBMIT_EXTRA_KYC_INFO',
  CREATE_INVESTOR_ACCOUNT = 'CREATE_INVESTOR_ACCOUNT',
  SUBMIT_MANAGED_PORTFOLIO_ANSWERS = 'SUBMIT_MANAGED_PORTFOLIO_ANSWERS',
  SUBMIT_MANAGED_PORTFOLIO_STRATEGY = 'SUBMIT_MANAGED_PORTFOLIO_STRATEGY',
  SUBMIT_THIRD_PARTY_KYC = 'SUBMIT_THIRD_PARTY_KYC',
  UNSUPPORTED_MANAGED_PORTFOLIO = 'UNSUPPORTED_MANAGED_PORTFOLIO',
  DEPOSIT_REQUIRED = 'DEPOSIT_REQUIRED',

  // IRA
  VERIFY_IRA_BENEFICIARIES = 'VERIFY_IRA_BENEFICIARIES',
  SET_IRA_FEE = 'SET_IRA_FEE',
  SIGN_IRA_DOC = 'SIGN_IRA_DOC',
  FUND_IRA = 'FUND_IRA',

  // WALLET
  CREATE_WALLET = 'CREATE_WALLET',

  // Multi Account Actions
  COMPLETE_SETUP = 'COMPLETE_SETUP',
  SETUP_PORTFOLIO = 'SETUP_PORTFOLIO',

  // Generic
  CONTACT_IR = 'CONTACT_IR',
  EDIT_USER_QUIZ_ANSWERS = 'EDIT_USER_QUIZ_ANSWERS',
}

export type AppActionParamsMap = BaseAppActionParamsMap<{
  // KYC
  [AppAction.START_KYC]: { investorEntityId: string | number; investorEntityType: string };
  [AppAction.CONTINUE_KYC]: {
    investorEntityId: string | number;
    investorEntity: InvestorEntity;
  };

  // Beneficial Owners KYC
  [AppAction.SUBMIT_BENEFICIAL_OWNERS_KYC_INFO]: {
    investorEntityId: string | number;
    investorEntityType: string;
  };
  [AppAction.UPLOAD_BENEFICIAL_OWNERS_KYC_DOCS]: {
    investorEntityId: string | number;
    investorEntityType: string;
  };
  [AppAction.INVITE_BENEFICIAL_OWNERS]: {
    investorEntityId: string | number;
    investorEntityType: string;
  };

  // Bank Linking
  [AppAction.LINK_BANK_ACCOUNT]: { investorEntityId: string | number };
  [AppAction.VERIFY_MICRO_DEPOSITS]: { investorEntityId: string | number };

  // Accreditation
  [AppAction.SUBMIT_ACCREDITATION]: {
    investorEntityId: string | number;
    investorEntity?: InvestorEntity;
    investorEntityStatus?: InvestorEntityStatus;
  };
  [AppAction.EDIT_ACCREDITATION]: {
    investorEntityId: string | number;
    investorEntity?: InvestorEntity;
    investorEntityStatus?: InvestorEntityStatus;
  };
  [AppAction.RENEW_ACCREDITATION]: {
    investorEntityId: string | number;
    investorEntity?: InvestorEntity;
    investorEntityStatus?: InvestorEntityStatus;
  };
  [AppAction.UPDATE_ACCREDITATION_DOCUMENTATION]: {
    investorEntityId: string | number;
    investorEntity?: InvestorEntity;
    investorEntityStatus?: InvestorEntityStatus;
  };

  // Managed Portfolios
  [AppAction.CREATE_INVESTOR_ENTITY]: {};
  [AppAction.SUBMIT_EXTRA_KYC_INFO]: { investorEntityId: string | number };
  [AppAction.CREATE_INVESTOR_ACCOUNT]: {
    investorEntityType: string | InvestorEntityType;
    investorEntityId: string | number;
    managementStrategy?: string;
  };
  [AppAction.SUBMIT_MANAGED_PORTFOLIO_ANSWERS]: { investorEntityId: string | number };
  [AppAction.SUBMIT_MANAGED_PORTFOLIO_STRATEGY]: { investorEntityId: string | number };
  [AppAction.TRANSACTIONS]: {
    investorEntityId: string | number;
  };
  [AppAction.SUBMIT_THIRD_PARTY_KYC]: { investorEntityId: string | number };
  [AppAction.UNSUPPORTED_MANAGED_PORTFOLIO]: { investorEntityId?: string | number };

  // WALLET
  [AppAction.CREATE_WALLET]: { investorEntityId: string | number; managementStrategy: string };

  // IRA
  [AppAction.VERIFY_IRA_BENEFICIARIES]: { investorEntityId: string | number };
  [AppAction.SET_IRA_FEE]: { investorEntityId: string | number };
  [AppAction.SIGN_IRA_DOC]: { investorEntityId: string | number };
  [AppAction.FUND_IRA]: { investorEntityId: string | number };

  // Multi Account Actions
  [AppAction.COMPLETE_SETUP]: { investorEntityId?: string | number };
  [AppAction.SETUP_PORTFOLIO]: {};

  // Generic
  [AppAction.EDIT_USER_QUIZ_ANSWERS]: {};
  [AppAction.CONTACT_IR]: {};

  [AppAction.DEPOSIT_REQUIRED]: { investorEntityId?: string | number };
}>;

export type AppActionParams<Action extends AppAction> = AppActionParamsMap[Action];

export const AppActionCTAText: Partial<Record<AppAction, string>> = {
  // KYC
  [AppAction.START_KYC]: 'Verify identity',
  [AppAction.CONTINUE_KYC]: 'Continue identity verification',

  // Beneficial Owners KYC
  [AppAction.SUBMIT_BENEFICIAL_OWNERS_KYC_INFO]: 'Add beneficial owners',
  [AppAction.UPLOAD_BENEFICIAL_OWNERS_KYC_DOCS]: 'Upload beneficial owner documents',
  [AppAction.INVITE_BENEFICIAL_OWNERS]: 'Invite beneficial owners',

  // Bank Linking
  [AppAction.LINK_BANK_ACCOUNT]: 'Link bank account',
  [AppAction.VERIFY_MICRO_DEPOSITS]: 'Verify micro-deposits',

  // Accreditation
  [AppAction.SUBMIT_ACCREDITATION]: 'Submit accreditation',
  [AppAction.EDIT_ACCREDITATION]: 'Edit accreditation',
  [AppAction.RENEW_ACCREDITATION]: 'Renew accreditation',
  [AppAction.UPDATE_ACCREDITATION_DOCUMENTATION]: 'Update accreditation documentation',

  //Account
  [AppAction.CREATE_INVESTOR_ENTITY]: 'Set up a portfolio',
  [AppAction.CREATE_INVESTOR_ACCOUNT]: 'Set up a portfolio',

  // Managed Portfolios
  [AppAction.SUBMIT_EXTRA_KYC_INFO]: 'Verify identity',
  [AppAction.SUBMIT_MANAGED_PORTFOLIO_ANSWERS]: 'Finish questionnaire',
  [AppAction.SUBMIT_MANAGED_PORTFOLIO_STRATEGY]: 'Select strategy',
  [AppAction.SUBMIT_THIRD_PARTY_KYC]: 'Verify identity',
  [AppAction.TRANSACTIONS]: 'View transactions',

  // WALLET
  [AppAction.CREATE_WALLET]: 'Create Wallet',

  // IRA
  [AppAction.VERIFY_IRA_BENEFICIARIES]: 'Verify beneficiaries',
  [AppAction.SET_IRA_FEE]: 'Review fees and pricing',
  [AppAction.SIGN_IRA_DOC]: 'eSign your documents',
  [AppAction.FUND_IRA]: 'Fund your IRA',

  // Multi Account Actions
  [AppAction.COMPLETE_SETUP]: 'Continue set up',
  [AppAction.SETUP_PORTFOLIO]: 'Set up a portfolio',

  // Generic
  [AppAction.CONTACT_IR]: 'Contact support',
  [AppAction.EDIT_USER_QUIZ_ANSWERS]: 'Edit Preferences',

  [AppAction.DEPOSIT_REQUIRED]: 'Finish Funding',
};

export type AppActionConfig<Action extends AppAction> = {
  action: Action;
  params: AppActionParams<Action>;
  cta?: AppActionCTA<Action>;
};

export type AppActionCTA<Action extends AppAction> = {
  text: (typeof AppActionCTAText)[Action];
  callback: () => void;
};

export type AppActionCallback<Action extends AppAction, ExtraParams extends Object> = (
  params: AppActionParamsMap[Action],
  extraParams: ExtraParams
) => void;

export type AppActionCallbackMap<ExtraParams extends Object> = {
  [Key in AppAction]: AppActionCallback<Key, ExtraParams>;
};
