import { gql } from 'graphql-request';

import { graphqlApi, Tags } from '../common';
import {
  InvestmentRolloverAmountRequestGraphQl,
  InvestmentRolloverAmountResponseGraphQl,
  InvestmentsByStatusRequestGraphQl,
  InvestmentsByStatusResponseGraphQl,
} from './investments.model';

export const portfolioGraphQLApi = graphqlApi
  .enhanceEndpoints({
    addTagTypes: [Tags.PortfolioInvestmentsByStatus, Tags.PortfolioInvestmentRolloverAmount],
  })
  .injectEndpoints({
    endpoints: builder => ({
      getPortfolioInvestments: builder.query<
        InvestmentsByStatusResponseGraphQl,
        InvestmentsByStatusRequestGraphQl
      >({
        providesTags: [Tags.PortfolioInvestmentsByStatus],
        query: ({
          asOfDate,
          investorId,
          status,
          userId,
          managementStrategy,
          actionableItemsOnly,
        }) => ({
          document: gql`
            query getPortfolioInvestments(
              $asOfDate: String
              $investorId: String
              $userId: String!
              $status: [String]
              $managementStrategy: String
              $actionableItemsOnly: Boolean
            ) {
              investmentByStatus(
                asOfDate: $asOfDate
                investorAccountId: $investorId
                portfolioId: $userId
                status: $status
                managementStrategy: $managementStrategy
                actionableItemsOnly: $actionableItemsOnly
              ) {
                ACTIVE {
                  gates
                  investments {
                    currentValue
                    fundType
                    investmentAmount
                    investorAccountId
                    noteTitle
                    noteUrlHash
                    performanceStatus
                    requiresUserAction
                    termCurrent
                    termPercentage
                    termPhrase
                    termRemaining
                    allowRollover
                    rolloversScheduled
                  }
                  totals {
                    currentValue
                    investmentAmount
                  }
                }
                PENDING {
                  gates
                  investments {
                    currentValue
                    fundType
                    investmentAmount
                    investorAccountId
                    noteTitle
                    noteUrlHash
                    performanceStatus
                    requiresUserAction
                    termCurrent
                    termPercentage
                    termPhrase
                    termRemaining
                    allowRollover
                    rolloversScheduled
                  }
                  totals {
                    currentValue
                    investmentAmount
                  }
                }
                MATURED {
                  investments {
                    fundType
                    investmentAmount
                    investorAccountId
                    irr
                    netTotalRepaid
                    noteTitle
                    noteUrlHash
                    termCurrent
                    termPercentage
                    termPhrase
                    termRemaining
                    allowRollover
                    rolloversScheduled
                  }
                  totals {
                    investmentAmount
                    irr
                    netTotalRepaid
                  }
                }
                COMMITMENT_PENDING {
                  commitments {
                    noteUrlHash
                    noteTitle
                    investorAccountId
                    waitlisted
                    amount
                    launchedDate
                    terminationDate
                    termPhrase
                    termPercentage
                    termRemaining
                    termCurrent
                    performanceStatus
                    fundType
                    fundProvider
                    fundExpensesFeeType
                  }
                  totals {
                    amount
                  }
                }
                COMMITMENT_ACTIVE {
                  commitments {
                    noteUrlHash
                    noteTitle
                    investorAccountId
                    waitlisted
                    amount
                    launchedDate
                    terminationDate
                    termPhrase
                    termPercentage
                    termRemaining
                    termCurrent
                    performanceStatus
                    fundType
                    fundProvider
                    fundExpensesFeeType
                  }
                  totals {
                    amount
                  }
                }
              }
              lastCommentary(userId: $userId) {
                offerings
              }
            }
          `,
          variables: {
            asOfDate,
            investorId,
            status,
            userId,
            managementStrategy,
            actionableItemsOnly,
          },
        }),
      }),

      getRolloverAmount: builder.query<
        InvestmentRolloverAmountResponseGraphQl,
        InvestmentRolloverAmountRequestGraphQl
      >({
        providesTags: [Tags.PortfolioInvestmentRolloverAmount],
        query: ({ userId, investorId, noteUrlHash }) => ({
          document: gql`
            query getRolloverAmount($investorId: String!, $userId: String!, $noteUrlHash: String!) {
              maxRolloverAmount(
                portfolioId: $userId
                investorAccountId: $investorId
                noteUrlHash: $noteUrlHash
              ) {
                userId
                maturityDate
                rolloverAmountPerInvestment
                totals {
                  investmentIds
                  maxRolloverAmount
                }
              }
            }
          `,

          variables: {
            userId,
            investorId,
            noteUrlHash,
          },
        }),
      }),

      getDocuments: builder.query<any, any>({
        query: ({ fundId, investorAccountId }) => ({
          document: gql`
            query getPcap($investorAccountId: String!, $fundId: String!) {
              pcap(investorAccountId: $investorAccountId, fundId: $fundId) {
                available
              }
            }
          `,
          variables: {
            investorAccountId,
            fundId,
          },
        }),
      }),
    }),
  });
